<template>
  <div class="col-lg-9">

    <!--begin::Signup-->
    <div class="login-form login-signin">
      <div class="text-center mb-5 mb-lg-5">
        <!--        <h3 class="font-size-h1">Sign In</h3>-->
        <img :src="getLogo()" alt="" style="width: 40%">
        <!--        <p class="text-muted font-weight-semi-bold">-->
        <!--          Enter your email and password-->
        <!--        </p>-->
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
            role="alert"
            v-bind:class="{ show: errors.length }"
            class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
            id="example-input-group-0"
            label=""
            label-for="example-input-0"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              id="example-input-0"
              name="example-input-0"
              v-model="$v.form.full_name.$model"
              :state="validateState('full_name')"
              aria-describedby="input-0-live-feedback"
              placeholder="Company Name"
          ></b-form-input>

          <b-form-invalid-feedback id="input-0-live-feedback">
            Company Name is required (Minimum 3 characters)..
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id="example-input-group-1"
            label=""
            label-for="example-input-1"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              id="example-input-1"
              name="example-input-1"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
              placeholder="Email address"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id="example-input-group-2"
            label=""
            label-for="example-input-2"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              type="password"
              id="example-input-2"
              name="example-input-2"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
              placeholder="Password"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required (Minimum 6 characters).
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
            class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="font-weight-bold font-size-3 text-dark-60">
            Already have an account?
            <router-link
                class="font-weight-bold font-size-3 ml-2"
                :to="{ name: 'login' }"
            >
            Sign In!
          </router-link>
              </span>
          <button
              ref="kt_signup_submit"
              style="color: #fff"
              class="btn btn-theme font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Submit
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signup-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {mapState} from "vuex";
import {REGISTER, CLEAR_AUTH_ERRORS} from "@/core/services/store/auth.module";
import Swal from 'sweetalert2'
import {validationMixin} from "vuelidate";
import {email, required, minLength, maxLength} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      // Remove this dummy login info
      form: {
        full_name: "",
        email: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      full_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(300)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        full_name: null,
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const full_name = this.$v.form.full_name.$model;
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      // this.$store.dispatch(LOGOUT);
      this.$store.dispatch(CLEAR_AUTH_ERRORS);

      // set spinner to submit button
      const submitButton = this.$refs["kt_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      // send register request
      this.$store
          .dispatch(REGISTER, {
            email: email,
            password: password,
            full_name: full_name
          })
          .then((data) => {
            if (data._metadata.outcomeCode === 0) {
              Swal.fire('', data._metadata.message, 'success')
              submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
              );
              submitButton.disabled = false;
              this.resetForm()
            } else {
              submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
              );
              submitButton.disabled = false;
            }
          });
    },
    getLogo() {
      return process.env.BASE_URL + "media/logos/logo.jpg";
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
